<template>
  <div class="page-actions">
    <div class="row actions-row">
      <div class="col-lg-6 mb-4">
        <div class="h-100 card overflow-hidden">
          <div
            class="ps-0 card-header c-200 text-white"
            :class="'bgc-' + color(action_type)"
          >
            <div class="card-nav-main nav" :class="'bgc-' + color(action_type)">
              <ul
                v-if="user.ismanager || user.manageteams || user.managecompany"
                class="nav nav-pills p-0"
              >
                <li class="nav-item dropdown p-0">
                  <a
                    class="card-header-nav-btn nav-link p-0"
                    data-bs-toggle="dropdown"
                    href="#"
                    role="button"
                    aria-expanded="false"
                  >
                    <img
                      src="../assets/icons/icon-navigation-expand-light.svg"
                      alt=""
                    />
                  </a>
                  <ul class="dropdown-menu">
                    <li>
                      <a
                        :class="
                          (action_type === 'personal' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('personal')
                        "
                        @click.prevent="action_type = 'personal'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3">{{
                          $t("message.personalActions")
                        }}</span>
                      </a>
                    </li>
                    <li
                      v-if="
                        user.ismanager || user.manageteams || user.managecompany
                      "
                    >
                      <a
                        :class="
                          (action_type === 'team' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('team')
                        "
                        @click.prevent="action_type = 'team'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3">{{
                          $t("message.teamActions")
                        }}</span>
                      </a>
                    </li>
                    <li v-if="user.managecompany">
                      <a
                        :class="
                          (action_type === 'company' ? 'd-none' : 'c-50') +
                          ' bgc-' +
                          color('company')
                        "
                        @click.prevent="action_type = 'company'"
                        class="dropdown-item"
                        href="#"
                      >
                        <span class="overline text-sm ps-3">{{
                          $t("message.companyActions")
                        }}</span>
                      </a>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
            <span class="ps-3 overline text-sm"
              >{{ $t("message." + action_type) }}
              {{ $t("message.actions") }}</span
            >
          </div>
          <div class="card-body">
            <action-list
              @actions-refresh="handleActionRefresh"
              :type="action_type"
            ></action-list>
          </div>
        </div>
      </div>
      <div class="col-lg-6 mb-4">
        <div class="card h-100">
          <div v-if="message_type === 'team'" class="card-header bgc-blauw">
            <span class="overline text-sm">{{
              $t("message.teamMessages")
            }}</span>
          </div>
          <div
            v-if="message_type === 'company'"
            class="card-header bgc-okergeel"
          >
            <span class="overline text-sm">{{
              $t("message.companyMessages")
            }}</span>
          </div>
          <div class="card-body">
            <message-list
              @changeType="changeMessageType"
              ref="msgs"
              :type="message_type"
            ></message-list>
            <!--            <div class="action-filters row">-->
            <!--              <div class="col-auto">-->
            <!--                <search @search="handleSearch"></search>-->
            <!--              </div>-->
            <!--              <div class="col">-->
            <!--                <div class="btn-group" role="group" aria-label="Basic example">-->
            <!--                  <button @click="switchMessageType('team')" type="button" class="btn btn-sm  btn-secondary"-->
            <!--                          :class="message_type === 'team' ? 'active' : ''">Team-->
            <!--                  </button>-->
            <!--                  <button @click="switchMessageType('company')" type="button" class="btn  btn-sm btn-secondary"-->
            <!--                          :class="message_type === 'company' ? 'active' : ''">Company-->
            <!--                  </button>-->

            <!--                </div>-->
            <!--              </div>-->
            <!--              <div class="col-auto d-none">-->
            <!--                <input @change="handleDate" v-model="filterDate" type="date">-->
            <!--              </div>-->
            <!--            </div>-->
            <!--            <div class="pt-4 ms-3 border-start ps-5 messages-container">-->
            <!--              <div v-bind:key="message" v-for="(message,index) in messages"-->
            <!--                   :class="[index > 0 ? 'border-top' : '', message_type == 'company' ? 'bc-okergeel' : 'bc-blauw']"-->
            <!--                   class="message mb-3 pt-3 c-200">-->
            <!--                &lt;!&ndash;                {{message}}&ndash;&gt;-->
            <!--                <div class="mb-4 message-header row justify-content-between align-items-center">-->
            <!--                  <div class="col-auto">-->
            <!--                    <div class="text-sm">{{ moment(message.created_at).format('YYYY-MM-DD') }}</div>-->
            <!--                    <div v-if="message.type === 'message'" class="c-200" :class="message_type == 'company' ? 'c-okergeel' : 'c-blauw'">By-->
            <!--                      {{ message.author ? message.author.firstname + ' ' + message.author.lastname : 'Anonymous' }}-->
            <!--                    </div>-->
            <!--                  </div>-->
            <!--                  <div class="col-auto">-->
            <!--                     <span v-if="message.type === 'action'"-->
            <!--                           class="badge c-50 rounded-8 fw-normal c-body me-2" :class="message_type == 'company' ? 'bgc-okergeel' : 'bgc-blauw'">{{-->
            <!--                         $t('message.action')-->
            <!--                       }}</span>-->
            <!--                    <span v-else v-bind:key="tag" v-for="tag in message.tags"-->
            <!--                          class="badge bgc-blauw c-50 rounded-8 fw-normal c-body me-2">{{ tag.tagtext }}</span>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="mb-3 message-body row">-->
            <!--                  <div class="col-12">-->
            <!--                    <h3>{{ message.title }}</h3>-->
            <!--                    {{ message.body }}-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--                <div class="message-footer row">-->
            <!--                  <div class="col-auto ms-auto">-->
            <!--                    <button @click="showMessagePopup(message)" class="text-lg p-0 btn btn-link text-uppercase">Read-->
            <!--                      more-->
            <!--                    </button>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </div>-->
            <!--              <div v-if="messages.length < 1">-->
            <!--                <p class=" text-sentence d-block">{{$t('message.no_messages_available')}}</p>-->
            <!--              </div>-->
            <!--            </div>-->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import MessageModal from "@/views/components/modals/MessageModal";
// import Search from "@/views/components/simple/Search";
import ActionList from "@/views/components/ActionList";
import moment from "moment";
// import qs from 'qs';
import MessageList from "@/views/components/MessageList";

export default {
  components: {
    MessageList,
    ActionList,
    // Search
  },

  data() {
    return {
      moment: moment,
      action_type: "personal", //todo allow setting through route params
      message_type: "team", //todo allow setting through route params
      messages: [],
    };
  },
  methods: {
    handleActionRefresh() {
      this.$refs.msgs.getMessages();
    },
    color(type) {
      switch (type) {
        case "personal":
          return "paars";
        case "team":
          return "blauw";
        case "company":
          return "okergeel";
        default:
          return "paars";
      }
    },

    changeActionType(type) {
      this.action_type = type;
    },
    changeMessageType(type) {
      // console.log('change msg type')
      this.message_type = type;
    },
  },
};
</script>